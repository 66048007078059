import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    menuItem:'',//菜单项
  },
  getters: {
    GET_MENUITEM (state) {
      return state.menuItem
    },
  },
  mutations: {
    change_menu (state, data) {
      state.menuItem = data;
    },
  },
  actions: {
    action_menu ({ commit }, data) {
      commit("change_menu", data);
    },
  }
});

export default store;
